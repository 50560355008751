/* You can add global styles to this file, and also import other style files */
$primary-color: orangered;
$secondary-color: #444;
$board-color: rgb(26, 121, 42);
$border: solid 1px rgb(124, 124, 124);
$color-success: rgb(1, 104, 1);
$color-error: red;
$button-shadow: 2px 2px 5px rgba(0,0,0.5);


$striped: linear-gradient(
    -35deg, 
    rgba(255,255,255,1) 0%, 
    rgba(255,255,255,1) 48%, 
    rgba(255,0,0,1) 49%, 
    rgba(255,0,0,1) 51%, 
    rgba(255,255,255,1) 52%, 
    rgba(255,255,255,1) 100%);