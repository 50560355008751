@import './variables.scss';

html,
body {
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 14px;
	margin: 0;
	padding: 0;
	background-color: #294e86;
	color: $secondary-color;
}

.game-board-panel {
	width: 100%;
	max-width: 475px;
	margin: auto;
	margin-top: 0;
	padding: 0px;
	box-sizing: border-box;
	background-color: #294e86;
	border: solid 0px #ccc;
	position: relative;
	&.diceboard {
		min-height: calc(100vh + 100px);
	}
}

.board-panel {
	visibility: hidden;
	&.show {
		visibility: visible;
	}
}
h2 {
	margin: 0;
	padding: 5px 0px 8px 0px;
	font-size: 20px;
	line-height: 1;
}

h4 {
	margin: 0;
	padding: 5px;
	margin-bottom: 5px;
}

.btn {
	background-color: $primary-color;
	padding: 4px 12px;
	border: solid 0px #fff;
	font-weight: bold;
	color: #fff;
	border-radius: 10px;
	cursor: pointer;
	&:disabled,
	&[disabled] {
		background: rgba(255, 255, 255, .4) !important;
		cursor: default;
	}
	&.btn-transparent {
		background-color: rgba(0, 0, 0, .1);
		border: solid 1px rgba(0, 0, 0, .5);
		margin-left: 15px;
		padding: 2px 12px;
		color: #333;
	}
	&.btn-secondary {
		background: #333;
	}
}

.hide {
	display: none !important;
	&.show {
		display: block !important;
	}
}
.hidden {
	visibility: hidden !important;
	height: 0;
}

input {
	width: 40px;
	margin: 0px 2px;
	padding: 2px 5px;
	box-sizing: border-box;
	text-align: right;
	border-radius: 3px;
	border: solid 1px lighten($secondary-color, 15);
	font-size: 15px;
	letter-spacing: 1px;
	cursor: pointer;

	&.endgame {
		border: 0;
		margin: 0;
		padding: 0px 1px;
		font-size: 14px;
		&.total {
			font-weight: bold;
		}
	}

	&.filled {
		background: rgba(255, 255, 255, .1);
		border-color: lighten($secondary-color, 55);
	}
}

.error {
	color: $color-error;
}
.info {
	color: inherit;
}
.success {
	color: $color-success;
}
.overLayer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, .3);
	display: none;
	z-index: 5;
	&.transparent {
		background-color: rgba(0, 0, 0, 0);
	}
}

.popup {
	position: fixed;
	width: 320px;
	margin: 15px;
	padding: 15px 12px 20px 12px;
	top: calc(50% - 220px);
	left: calc(50% - 175px);
	box-shadow: 3px 3px 10px rgba(0, 0, .15);
	max-height: 440px;
	background: #fff;
	border: solid 0px #aaa;
	z-index: 10;
	overflow: hidden;
	overflow-y: auto;
	color: #000;
	box-sizing: border-box;
	text-align: left !important;
	border-radius: 5px;
	h4 {
		font-size: 18px;
		margin-top: 0;
		padding-top: 0;
	}
	.info {
		margin-top: -2px;
		font-size: 15px;
		padding: 5px 10px;
		margin-bottom: 10px;
		border: solid 1px #a6c0cf;
		background-color: #dce7ee;
	}
	.row.link {
		padding: 5px 8px !important;
		width: 100%;
		border: solid 1px #aaa;
		box-sizing: border-box;
		cursor: pointer;
		background-color: #eee;
		border-radius: 3px;
		font-weight: normal;
		margin: 5px 0px;
	}
	.row {
		background: #fff;
	}
}

.col {
	padding: 3px 5px;
	align-items: stretch;
	position: relative;

	&.endgame-width {
		width: 41px;
		text-align: right;
		padding: 3px 2px;
	}
	&.flex {
		flex: 1;
	}
	&.action-button {
		width: 100px;
	}
	&.description {
		flex: 1;

		.remarks {
			display: inline-block;
			margin-left: 6px;
			font-size: 12px;
			font-style: italic;
			font-weight: normal;
			white-space: nowrap;
		}
	}

	&.col-player {
		display: flex;
		flex-direction: row;
		margin: 0;
		padding: 0;
	}
	&.block {
		display: block;
	}
	&.col-value {
		position: relative;
		&.striped input {
			border-color: rgb(235, 53, 53);
			color: transparent;
			position: relative;
			background: rgb(255, 255, 255);
			background: $striped;
		}

		span.sign {
			display: none;
			position: absolute;
			z-index: 4;
			padding: 0px 3px;
			width: 16px;
			text-align: center;
			background-color: $secondary-color;
			color: #fff;
			font-size: 11px;
			margin: 6px;
			margin-left: -11px;
			border-radius: 10px;
			font-weight: normal;
			&.total {
				margin-top: 0px;
				margin-left: 2px;
				display: block;
				line-height: 1;
				padding: 3px 12px 3px 12px;
			}
			&.error {
				border: solid 1px red;
				color: red;
				font-size: 14px;
				background-color: rgba(255, 255, 255, 1);
			}
			&.success {
				background-color: green;
				display: none;
			}
			&.info {
				display: none;
			}
		}
	}

	.list-field {
		border: solid 1px #aaa;
		margin: 0px;
		padding: 5px;
		width: 37px;
	}

	.dice-select {
		padding-bottom: 50px !important;
	}

	.dice-select-item {
		position: relative;
		display: inline-block;
		width: 42.5px;
		margin: 2px;
		border: solid 1px #777;
		line-height: 30px;
		text-align: center;
		font-size: 16px;
		cursor: pointer;
		padding: 3px 5px;
		box-sizing: border-box;

		&:hover {
			background-color: $secondary-color;
			color: #fff;
		}

		&.striped {
			color: transparent !important;
			font-size: 16px;
			border-color: rgba(red, .5);
			position: relative;
			z-index: 6;
			background: rgb(255, 255, 255);
			background: $striped;
		}

		&.active {
			background-color: $primary-color !important;
			color: #fff;
			border-color: $primary-color;
			&::after {
				color: #fff !important;
			}
		}
	}
}

.row {
	display: flex;
	flex-direction: row;
	border-bottom: solid 1px lighten($secondary-color, 45);
	background: rgb(230, 230, 230);
	background: linear-gradient(
		202deg,
		rgba(230, 230, 230, 1) 0%,
		rgba(255, 255, 255, 1) 50%,
		rgba(231, 231, 231, 1) 100%
	);
	padding: 0 5px 0 5px;
	align-items: center;
	text-align: left;
	&.row-item:nth-child(odd) {
		background: #f8f8f8;
	}
	&.transparent {
		background: transparent !important;
	}
	&.top {
		align-items: baseline;
	}
	&.endgame {
		background: #fff;
		padding: 4px 1px;
		.player-colors-endgame {
			margin-right: 4px;
			display: inline-block;
			width: 5px;
			height: 20px;
			border-radius: 2px;
		}
	}
	&.endgame-header {
		background: #c3dbf7;
		border-top: solid 1px #b7b7b7;
	}
	&.text-white {
		color: white;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, .05);
	}
	&.sub-total,
	&.total {
		font-weight: bold;
	}
	&.total-general {
		font-weight: bold;
		input {
			font-weight: bold;
		}
		&.dice-board-margin {
			margin-bottom: 65px;
		}
	}
	&.sub-total {
		background: lighten($secondary-color, 70);
		border-color: lighten($secondary-color, 40);
		border-top: solid 1px $secondary-color;
		.col {
			input {
				font-weight: bold;
				background-color: transparent;
				border-color: lighten($secondary-color, 70);
			}
		}
	}
	&.bonus {
		font-weight: bold;
		background: lighten($secondary-color, 70);
		border-color: lighten($secondary-color, 40);
		.col {
			padding-top: 3px !important;
			padding-bottom: 3px !important;
		}
		input {
			font-weight: bold;
			background-color: transparent;
			border-color: transparent;
			color: #999;
			&.success {
				color: $color-success;
				background-color: lighten($color-success, 67);
				border-color: $color-success;
			}
			&.error {
				color: $color-error;
				background-color: lighten($color-error, 40);
				border-color: $color-error;
			}
		}
	}
	&.block {
		padding: 10px;
		background: transparent !important;
		.col.block {
			margin-bottom: 5px;
		}
	}
	&.no-border {
		border: 0;
	}
	&.no-margin {
		margin: 0;
		padding: 0;
	}
	&.column {
		flex-direction: column;
	}
	&.center {
		text-align: center !important;
	}
	&.total {
		padding-top: 2px !important;
		padding-bottom: 2px !important;
		text-transform: uppercase;
		background: #c3dbf7;
		border-color: $secondary-color;
		color: #111;
		input {
			color: #000;
			background: transparent;
			border: 0;
			padding-top: 4px;
			padding-bottom: 4px;
			font-weight: bold;
		}
	}
	&.total-general {
		font-size: 16px;
		text-transform: uppercase;
		background: rgb(243, 242, 171) !important;
		border-color: $secondary-color !important;
		color: #111;
		padding-top: 5px !important;
		padding-bottom: 5px !important;
		input {
			font-size: 16px;
			border: 0;
			background-color: transparent;
			color: #000;
			padding: 5px;
			border-radius: 6px;
		}
	}
	&.popup-row {
		border: 0;
		background: transparent;
		font-size: 17px;
		input {
			font-size: 17px;
			border: 0;
			background-color: transparent;
			&.bold {
				font-weight: bold;
			}
		}
	}
	&.action-buttons {
		background: transparent;
		padding-top: 20px;
		border-bottom: 0;
		.btn {
			margin-right: 15px;
		}
	}
	&.part {
		padding: 0px 0 0 0;
		border: 0;
		font-weight: bold;
		.col {
			display: none;
		}
	}
}
